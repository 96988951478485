<template >
  <div class="card card_mobile" >
    <div class='card_wrapper'>
      <img src='/img/logo.svg' class='card_image'>
    <img :src="this.photo" alt="photo" class="photo">
    </div>
    <div class="info info_left" >
      <span class="name">{{this.name}}</span>
      <span class="description">{{this.desc}}</span>
    </div>

  </div>

</template>

<script>
export default {
  name: 'WorkerCard',
  props: {
    desc: String,
    name: String,
    photo: String,

  },
};
</script>


<style scoped>
@media (max-width: 620px) {
  .card_wrapper {
    position: relative;
    left: 50px;
  }
  .card_image {
    left: -90px;

  }
  .photo {
    padding: 15px;
  }
}

.card {
  position: relative;
}
.photo {
  position: relative;
  z-index: 2;
  padding: 25px;
  background: #f5f7f8;
}
.card_image {
  position: absolute;
  z-index: 1;
  right: 150px;
  opacity: 0.3;
  /*top: -30px;*/

}
@media screen and (max-width: 900px) {
  .card {
    flex-direction: column;
  }
  .card_mobile{
    flex-direction:column-reverse;

  }
  .name{
    margin-top: 12px;
    margin-bottom: 4px;
  }
  .info{
    align-items: center;
  }
}
@media screen and (min-width: 900px) {

  .left_photo{
    border-radius: 100px 0px 0px 0px;
  }
  .right_photo{
    border-radius: 0px 100px 0px 0px;
  }
  .name{
    margin-bottom: 18px;
  }

}
.card{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.name{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  max-width: 240px;
  text-align: center;
  margin-top: 40px;
}
.description{
  max-width: 240px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  text-align: center;
}
.info{
  display: flex;
  flex-direction: column;
}
 img{
   max-width: 260px;
   width: 260px;
   border-radius: 50%;
   max-height: 260px;
   object-fit: cover;
   object-position: top;
 }
</style>
