<template>
  <div class="container">
    <div class="img_wrapper">
      <img src="/img/arrow.png" alt="arrow" />
    </div>
    <h2 class="mb-30">Давайте вместе с вами разберемся, сколько будет стоить ваш проект?</h2>
    <span class="mb-30">Ваше индивидуальное решение</span>
    <vs-row justify="center" align="center" v-if="Number(money.sale_amount) !== 0">
      <vs-col :lg="6" :sm="12" :xs="12">
        <div class="money_card_wrapper mb-30 pd-20" style="justify-content: flex-end">
          <MoneyCard :sum="money.full_price" desc="Стоимость работ" />
        </div>
      </vs-col>
      <vs-col :lg="6" :sm="12" :xs="12">
        <div class="money_card_wrapper mb-30 pd-20">
          <MoneyCard :sum="`-${money.sale_amount}`" desc="Ваша скидка" />
        </div>
      </vs-col>
    </vs-row>
    <div style="display: flex; padding-top: 10px">
      <div class="sum_wrapper">
        <span>Итог по сумме</span>
<!--        <span class="big">{{  }}</span>-->
        <div class='main-sum-wrapper'>
          <PricesNew class="big" :target-value='money.price_discounted'></PricesNew> <span class="big main-sum-description"> руб.</span>
        </div>
        <span v-if="money.spec_work_price && money.spec_work_price !== '0.00'" class="spec_price">
          +{{ money.spec_work_price }} руб спецмонтаж
        </span>
      </div>
      <div v-if="checkWidth()" class="arrow_wrapper">
        <img src="/img/arrow-bg-prices.png" alt="arrow" />
      </div>
    </div>
    <p style="text-align: center">
      * Предложение ограничено, за подробной информацией обращайтесь к персональному менеджеру
    </p>
    <div class="line"></div>
  </div>
</template>

<script>
import MoneyCard from '@/components/Prices/PriceCard.vue'
import PricesNew from '@/components/PricesNew';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Prices',
  components: { PricesNew, MoneyCard },
  props: {
    money: Object,
  },
  methods: {
    checkWidth: () => {
      // console.log(window.screen);
      return window.screen.width > 500
    },
  },
}
</script>

<style scoped>
@media (max-width: 700px) {

  .arrow_wrapper {
    display: none;
  }
}
.main-sum-wrapper {
  display: flex;
}
.main-sum-description {
  margin-left: 10px;
}
.arrow_wrapper {
  position: relative;
  left: 60px;
}
.sum_wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}
.img_wrapper {
  display: flex;
  background-color: #ffba00;
  background-size: 39px 39px;
  position: relative;
  border-radius: 50%;
}
.img_wrapper img {
  max-width: 66px;
}

@media screen and (max-width: 900px) {
  .money_card_wrapper {
    flex-direction: column;
    align-items: center;
  }
  .container {
    padding-top: 26px;
  }
  .line {
    width: 70%;
  }
  .img_wrapper {
    top: -66px;
  }
  h2 {
    font-size: 20px;
  }
}

@media screen and (min-width: 900px) {
  .container {
    padding-top: 96px;
  }
  .img_wrapper {
    top: -135px;
  }
  .pd-20 {
    padding: 0 20px;
  }
  .line {
    width: 50%;
  }
  h2 {
    font-size: 22px;
  }
}

.mb-30 {
  margin-bottom: 30px;
}
h2 {
  max-width: 967px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}
span {
  max-width: 309px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #f5f7f8;
}
.line {
  border-bottom: 1px solid rgba(208, 217, 222, 1);
}
.money_card_wrapper {
  display: flex;
  align-content: space-around;
}
.sum_wrapper {
  margin-bottom: 70px;
}
.big {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 150%;
  text-align: center;

  color: #000000;
}
</style>
