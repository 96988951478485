<template>
  <div class="card">
    <div class="main-line">
      <div class="line" :class="{ line_colored: left }" id="left"></div>
      <div class="line" :class="{ line_colored: right }" id="right"></div>
    </div>
    <div class="circle_wrapper">
      <div class="circle white" :class="{ brown: circle }">
        <span>{{ this.el.id }}</span>
      </div>
    </div>
    <div class="img_wrapper">
      <img :src="this.el.photo" alt="photo" />
    </div>
    <div class="description">
      <span id="title">
        {{ this.el.title }}
      </span>
      <span id="text">
        {{ this.el.text }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderElement',
  props: {
    el: Object,
    active: Number,
  },
  data() {
    return {
      right: false,
      left: false,
      circle: false,
    }
  },
  methods: {
    checkStage() {
      if (this.active > this.el.id) {
        this.right = true
        this.left = true
        this.circle = true
      } else if (this.active < this.el.id) {
        this.right = false
        this.left = false
      } else {
        this.right = false
        this.left = true
        this.circle = true
      }
      return true
    },
  },
  created() {
    this.checkStage()
  },
}
</script>

<style scoped>
.line {
  width: 140px;
  border-color: rgba(208, 217, 222, 1) !important;
  border-width: 2px;
}
.line_colored {
  border-color: #ffba00 !important;
}
#right {
  border-top: 2px solid;
}
#left {
  border-top: 2px solid;
  border-right: 2px solid;
}
.main-line {
  height: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
#stage {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}
.circle {
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* не забываем о кроссбраузерности */
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle span {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}
.img_wrapper {
  display: flex;
  justify-content: center;
}
.circle_wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: -30px;
}
.white {
  background: rgba(255, 255, 255, 1);
}
.brown {
  background: #ffba00;
  color: rgba(255, 255, 255, 1);
}
img {
  max-width: 260px;
  aspect-ratio: 520/450 !important;
  object-fit: contain;
}
.description {
  margin-top: 20px;
  max-width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}
#text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}
</style>
