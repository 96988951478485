<template>
  <div class="card">
    <span class="description">{{ this.desc }}</span>


    <div class='main-sum-wrapper'>
      <PricesNew style="color: #ffba00"  class="money" :target-value='this.sum'></PricesNew> <span class="big main-sum-description"> руб.</span>
    </div>
    <!-- <span v-else class="money" style="color: #ffba00">{{ this.sum }} руб</span> -->

  </div>
</template>

<script>
import PricesNew from '@/components/PricesNew';

export default {
  name: 'MoneyCard',
  components: { PricesNew },
  props: {
    desc: String,
    sum: String,
  },
  mounted() {

  }
}

</script>

<style scoped>
@media (max-width: 700px) {
  .main-sum-description {
    font-size: 28px;
  }
  .arrow_wrapper {
  display: none;
  }
}

.main-sum-wrapper {
  display: flex;
}
.main-sum-description {
  margin-left: 10px;

}
@media screen and (min-width: 360px) {
  .card {
    width: 360px;
  }
}

@media screen and (max-width: 360px) {
  .card {
    width: 250px;
  }
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 20px 50px rgb(0 0 0 / 10%);
  border-radius: 10px;
  justify-content: center;
}
.money {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  margin: 0;
  color: #000000;
}
.counter-container.money {
  font-size: 28px;

}


.description {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  text-align: center;

  color: #000000;
}
</style>
