<template>
    <span class='title-name'>{{ text }}</span>
</template>

<script>
export default {
  name:'HeaderTitleNew',
  props:{
    message:String,
  },

  data() {
    return {
      text: "",
      index: 0,
      timer: null
    };
  },
  methods: {
    typeWriter() {
      this.text += this.message.charAt(this.index);
      this.index++;
      if (this.index === this.message.length) {
        clearInterval(this.timer);
        setTimeout(() => {
          this.timer = setInterval(this.deleteText, 50);
        }, 2000); // время до очистки
      }
    },
    deleteText() {
      this.text = this.text.slice(0, -1);
      if (this.text.length === 0) {
        clearInterval(this.timer);
        setTimeout(() => {
          this.timer = setInterval(this.typeWriter, 50);
          this.index = 0;
        }, 1000); // задержка до нового набора текста
      }
    }
  },
  mounted() {
    this.timer = setInterval(this.typeWriter, 100);
  }
};
</script>

<style scoped>

</style>
