<template>
  <div class="container">

    <VideoBackground></VideoBackground>
    <div class='header-wrapper' style='z-index: 3;'>
      <img width='146' height='50' src='/img/logo_header.png'>
      <a class="header-phone" href='tel:74951522626'>+7 (495) 152-26-26</a>
    </div>
    <h1 class='header-title'>
      ИНДИВИДУАЛЬНОЕ РЕШЕНИЕ ПО РЕМОНТНЫМ РАБОТАМ ДЛЯ ВАС,
<!--      <span ><HeaderTitleArray :words="words" :delay="150"></HeaderTitleArray></span>-->
<!--      {{ name }}-->
<!--      <HeaderTitle></HeaderTitle>-->
      <HeaderTitleNew :message='name'></HeaderTitleNew>
    </h1>

  </div>
</template>

<script>
import VideoBackground from '@/components/VideoBackground';
import HeaderTitle from '@/components/HeaderTitle';
import HeaderTitleArray from '@/components/HeaderTitleArray';
import HeaderTitleNew from '@/components/HeaderTitleNew';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  components: { HeaderTitleNew, HeaderTitleArray, HeaderTitle, VideoBackground},
  props: {
    name: String,
  },
  data(){
    return{
      words: ['Татьяна','Tatiana','Tati','AAA']
    }

  }


}


</script>

<style scoped>
.header-wrapper {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 50px;
}
.header-phone {
  color: #ffba00;
  font-size: 20px;

}
.header-phone:hover {
  color: #ffffff;
}
@media screen and (max-width: 900px) {
  .header-wrapper {
    width: 80%
  }
  .header-wrapper img {
    width: 100px;
    height: 30px;

  }
  .header-phone {

    font-size: 12px;

  }
  h1 {
    font-size: 20px;
    margin: 10px;
  }
}
@media screen and (min-width: 900px) {
  h1 {
    font-size: 28px;
  }
}

.container {

  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container img {
  z-index: 1;
  max-width: 205px;

}
.container h1 {
  z-index: 1;
  max-width: 840px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 120px;
  margin-bottom: 30px;
}
.container .header-title {
  margin-top: 43vh;
  transform: translateY(-50%);
}
.container VideoPlayBack {
  z-index: 1;
}

.container .header-title .header-title-name {
  overflow: hidden;
  white-space: nowrap;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  border-right: 0.1em solid #000;
  padding-right: 10px;
}


</style>
<style>
.fade {
  opacity: 0;
  transition: opacity 0.5s ease;
}
</style>
