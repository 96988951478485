<script>
// eslint-disable vuejs-accessibility/mouse-events-have-key-events

import carousel from 'vue-owl-carousel';
import Card from './Card.vue';

export default {
  name: 'CardsSummary',
  props: {
    sums: Object,
  },

  created() {
  },
  mounted() {
    Object.keys(this.$refs).forEach((ref) => {
      this.$refs[ref][0].$el.classList.add('unhover');
      // console.log(this.$refs[ref][0].$el.classList);
    });
  },
  components: {
    Card,
    carousel,
  },
  methods: {
    handleClick(area) {
      this.$emit('cardClicked', area);
    },
    handleHover(area) {
      Object.keys(this.$refs).forEach((ref) => {
        if (ref === area) return;
        this.$refs[ref][0].$el.classList.add('hover');
        this.$refs[ref][0].$el.classList.remove('unhover');

      });
    },
    handleHoverOut(area) {
      Object.keys(this.$refs).forEach((ref) => {
        if (area === ref) return;
        this.$refs[ref][0].$el.classList.remove('hover');
        this.$refs[ref][0].$el.classList.add('unhover');

      });
    },
  },
};
</script>
<template>
   <!-- <div class="grid" :style="{
     gridTemplateColumns: Object.keys(sums).length<=4
     ? '1fr '.repeat(Object.keys(sums).length)
     : '1fr 1fr 1fr 1fr'
     }"> -->
      <carousel class="carousel"
      :margin="8" :nav='false'  :autoWidth="true" :dots="false"
      >
        <template slot="prev"><span class="nav-button nav-button-prev">Пред.</span></template>
        <template slot="next"><span class="nav-button nav-button-next">След.</span></template>
        <!-- <div
          slot="prevArrow"
          class="custom-slick-arrow"
          style="left: 10px;zIndex: 999"
        >
          <a-icon type="left-circle" />
        </div>
        <div slot="nextArrow"  class="custom-slick-arrow" style="right: 10px">
          <a-icon type="right-circle" />
        </div> -->
        <Card v-for="area in Object.keys(sums)"
          @click.native="handleClick(area)"
          :key="area" :sum="sums[area]" :area="area" :ref="area"/>
        </carousel>
    <!-- </div> -->
</template>
<style>
.ant-tabs .ant-tabs-left-content, .ant-tabs .ant-tabs-right-content {
  padding-bottom: 70px
}
</style>
<style scoped>
.carousel {
  position: relative;
}

.nav-button {
  position: absolute;
  background: #ffba00;
  padding: 8px;
  border-radius: 7px;
  bottom: -50px;
  left: 55%;
  transform: translate(-50%);
  cursor: pointer;
  user-select: none;
  color: #ffffff;
}
.nav-button-prev {
  left: 45%
}
@media (max-width: 1024px) {
  .nav-button-prev {
    left: 30%
  }
}
/* .carousel{
  height: 25rem;
  width: 50rem;
  border-radius: 1rem !important;
} */
.owl-stage {
  display: flex;
}
.carousel{
  max-width: 90vw !important;
}
.ant-carousel >>> .slick-slide {
  text-align: center;
  overflow: hidden;
}
.slick-slide > *{
  border-radius: 1rem !important;
}
.ant-carousel >>> .custom-slick-arrow {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}

.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}
.ant-carousel .slick-next::before {
  content: '';
}
.ant-carousel .slick-prev::before {
  content: '';
}
.grid{
  display: grid;
  margin: 5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  gap: 1rem;
}

@keyframes bigger{
  from{
    transform: scale(1);
  }
  to{
    transform: scale(1.05);
  }
}

/* @media screen and (max-width:850px) {
  .carousel{
    width: 33rem;
}
}
@media screen and (max-width:560px) {
 .carousel{
  width: 16rem;
 }
}
@media screen and (max-width:410px) {
 .carousel{
  width: 400px;
 }
}
@media screen and (max-width:430px) and (min-width:500px) {
 .carousel{
  width: 465px;
 }
} */

</style>
