<template>

    <span class='title-name header-title'>{{ currentText }}</span>

</template>

<script>
export default {
  name: 'HeaderTitle',
  data() {
    return {
      text: "Татьяна",
      currentText: "",
      timeout: 2000,
    };
  },
  mounted() {
    this.typeWriter();
  },
  methods: {
    typeWriter() {

        setInterval(()=>{
          let i = 0;
          const speed = 120;
          this.currentText= ""

          const timer = setInterval(() => {
            if (i < this.text.length) {
              this.currentText += this.text.charAt(i);
              i++;
            } else {
              setInterval(()=>{
                clearTimeout(timer)
              },3000)

            }
          }, speed);

        },this.timeout)




    }
  }
};
</script>

<style>
.title-name {
overflow: hidden;
border-right: 0.15em solid orange;
white-space: nowrap;
width: auto;
animation: typing 1.5s steps(30, end), blink-caret 1s step-end infinite;
  color: white
}

@keyframes typing {
from {
width: 0;
}
to {
width: 30ch;
}
}

@keyframes blink-caret {
  from,
      to {
    border-color: transparent;
  }
  50% {
  border-color: orange;
  }
}
</style>
