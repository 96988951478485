<template>
  <div class="container">
    <svg class='present-icon' width="42px" height="42px" viewBox="0 0 42 42" opacity='0.4' version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>present</title>
      <g id="present" class="animation-present" stroke="none" stroke-width="1" fill="" fill-rule="evenodd">
        <path d="M26.2672474,5.96199917 C28.5489054,7.91957507 27.7951491,10.8214427 24.813438,12.7037037 L29.4116129,12.7037037 C29.7352258,12.7037037 30,13.1490741 30,13.6934156 L30,17.6522634 C30,18.1966049 29.7352258,18.6419753 29.4116129,18.6419753 L20.88,18.6419753 L20.88,12.7037037 L19.76,12.7037037 L19.76,31 L16.24,31 L16.24,12.7037037 L15.12,12.7037037 L15.12,18.6419753 L6.5883871,18.6419753 C6.26477419,18.6419753 6,18.1966049 6,17.6522634 L6,13.6934156 C6,13.1490741 6.26477419,12.7037037 6.5883871,12.7037037 L11.186562,12.7037037 C8.20485089,10.8214427 7.45109462,7.91957507 9.73275257,5.96199917 C12.3210453,3.74134229 15.8651479,5.46058697 18,10.1358305 C20.1348521,5.46058697 23.6789547,3.74134229 26.2672474,5.96199917 Z M7.76,30.0230811 L7.76,19.7654321 L15.12,19.7654321 L15.12,31 L8.9376,31 C8.28992,31 7.76,30.5603865 7.76,30.0230811 Z M20.88,31 L20.88,19.7654321 L28.24,19.7654321 L28.24,30.0230811 C28.24,30.5603865 27.71008,31 27.0624,31 L20.88,31 Z M11.6014283,8.15929297 C10.6896669,8.95172415 11.6281666,10.6805654 15.571746,11.5738302 C15.5814793,11.5760349 15.5908731,11.5781677 15.6,11.5802469 C14.2330476,8.26359513 12.5142415,7.36594767 11.6014283,8.15929297 Z M24.3985717,8.15929297 C23.4857585,7.36594767 21.7669524,8.26359513 20.4,11.5802469 C20.4091269,11.5781677 20.4185207,11.5760349 20.428254,11.5738302 C24.3718334,10.6805654 25.3103331,8.95172415 24.3985717,8.15929297 Z" id="Present"  fill-rule="nonzero"></path>
      </g>
    </svg>
    <div class="wings">
      <vs-row justify="center" align="center">
        <vs-col w="1">
          <div class="wing">
            <img src="../../public/img/wing_left.png" alt="wing_left" />
          </div>
        </vs-col>
        <vs-col w="10" style="max-width: 840px">
          <div class="main_text">
            <h2>Мы обещали вам скидку:</h2>
            <p>-{{ sale }}% согласовано для вас на все ремонтные работы</p>
          </div>
        </vs-col>
        <vs-col w="1">
          <div class="wing">
            <img src="../../public/img/wing_right.png" alt="wing_right" />
          </div>
        </vs-col>
      </vs-row>
    </div>
    <p class="additional">
      *Так же просим не забывать, о том что у нас свой склад черновых материалов, и в случае если вы
      примете решение дальше двигаться с нами, мы подарим вам несгораемую скидку на все черновые
      материалы 10%
    </p>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Present',
  props: {
    sale: String,
  },
}
</script>

<style scoped>
.present-icon {
  width: 180px;
  height: 180px;
  background: center;
  position: relative;
  left: 15px;
  fill:#949494;
  transition: .3s all ease-in-out;
}
.present-icon:hover {
  fill: #ffba00;
}

.animation-present {
  float: left;
  animation-name: shuffle;
  animation-duration: 1.2s;
  animation-delay: 2s;
  animation-iteration-count: 6;
  animation-timing-function: linear;
  transform-origin: center center;
}

@keyframes shuffle {
  from {
    transform: translate(0px, 0px) scale(1);
  }

  10% {
    transform: translate(2px, -3px) scale(1) rotate(5deg);
  }
  20% {
    transform: translate(0px, 0px) scale(1) rotate(0deg);
  }
  40% {
    transform: translate(-2px, -3px) scale(1) rotate(-5deg);
  }
  60% {
    transform: translate(0px, 0px) scale(1);
  }
  to {
    transform: translate(0px, 0px);
  }
}

@media screen and (max-width: 900px) {
  .main_text p {
    font-size: 14px !important;
  }
  h2 {
    font-size: 16px !important;
  }
  .additional {
    margin-bottom: 54px;
    margin-top: 20px;
    padding: 0 12px;
    text-align: center !important;
  }
  .wing img {
    width: 100%;
  }
  .wings {
    padding: 0 14px;
  }
}
h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;

  color: #ffba00;
}
.main_text p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  max-width: 740px;
}
.main_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wing {
  display: flex;
  justify-content: center;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 54px;
}
#present {
  max-width: 140px;
  margin-bottom: 22px;
}
.additional {
  max-width: 814px;
  margin-bottom: 94px;
  margin-top: 20px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  display: flex;
  align-items: center;
  text-align: center;
}
</style>
