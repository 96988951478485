<template>
  <div class="container">
    <a :href="href" target="_blank"><button>написать личному менеджеру</button></a>
    <a :href="uri" :download="`Клиентское предложение ${this.client}.pdf`" target="_blank"
      ><button>Скачать смету в PDF</button></a
    >
  </div>
</template>

<script>
export default {
  name: 'ContactManager',
  props: {
    managerContact: String,
    managerName: String,
    client: String,
    dealID: String,
    uri: String,
  },
  data() {
    return {
      href: `https://wa.me/${this.managerContact}?text=${this.managerName},%20это%20${this.client}%20по%20договору%20№${this.dealID},%20у%20меня%20есть%20пара%20вопросов`,
    }
  },
  created() {

  },
  methods: {
    download() {
      const link = document.createElement('a')
      link.target = '_blank'
      link.href = `data:application/pdf;base64,${this.uri}`
      link.download = `Клиентское предложение ${this.client}.pdf`
      link.click()
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
}
button {
  margin-left: 1rem;
  height: 70px;
  background: #ffba00;
  border-radius: 6px;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: white;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

@media screen and (min-width: 360px) {
  button {
    width: 360px;
  }
}

@media screen and (max-width: 360px) {
  button {
    width: 250px;
  }
}

@media screen and (max-width: 750px) {
  .container {
    margin-bottom: 56px;
    flex-direction: column;
    align-items: center;
  }
  button {
    margin-left: 0rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .container {
    margin-bottom: 56px;
  }
}
@media screen and (min-width: 900px) {
  .container {
    margin-bottom: 90px;
  }
}
</style>
