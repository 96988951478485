<template>
  <div class="container">

    <a-tabs v-model="activeKey" :tab-position="'left'"    class="tabs tabs-nav-container--remove">
      <button id='tabs-menu' class='button-nav-menu' v-on:click='handleClickOnMenu'><span class='button-nav-menu--background'></span>Меню сметы</button>
      <a-tab-pane forceRender tab="Карточки" key="Карточки" class="cards">
        <CardsSummary :money="money"
                      v-if="activeKey==='Карточки'" :sums="sums" @cardClicked="handleClick"/>
      </a-tab-pane>
      <a-tab-pane tab="Общее" key="Общее">

        <TableSummary :money="money"
        overflow :sums="sums" :perRoom="perRoom" :salePerRoom="salePerRoom"/>
      </a-tab-pane>

      <a-tab-pane v-for="(table, key)  in tables" :key="key">
        <template #tab>
          <div v-if="activeKey===key">
            {{key}} <hr :style="{height: salePerRoom[key].sum!==perRoom[key].sum ? '.5rem' : '6px' }"/>
            <small v-if="salePerRoom[key].sum!==perRoom[key].sum">
              <strike style='color:#947959'>
                <span style='color:#947959'>{{perRoom[key].sum}}</span>
              </strike>
            </small>
            <hr :style="{height: salePerRoom[key].sum!==perRoom[key].sum ? '.5rem' : '6px' }"/>
            <span style='color:#947959'>
              <small>{{salePerRoom[key].sum}}</small>
            </span>
          </div>
          <div v-else>
            {{key}} <hr :style="{height: salePerRoom[key].sum!==perRoom[key].sum ? '.5rem' : '6px' }"/>
            <small v-if="salePerRoom[key].sum!==perRoom[key].sum">
              <strike style='color:#404040'>
                <span style='color:#404040'>{{perRoom[key].sum}}</span>
              </strike>
            </small>
            <hr :style="{height: salePerRoom[key].sum!==perRoom[key].sum ? '.5rem' : '6px' }"/>
            <span style='color:#404040'>
              <small>{{salePerRoom[key].sum}}</small>
            </span>
          </div>
        </template>
        <TableEstimate :money="money" overflow :products="tables[key]" :sums="sums[key]"/>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<style>
.ant-tabs-nav div div:nth-child(0) {
  display: none;
}
@media  (min-width: 1160px) {
  .button-nav-menu {
    display: none!important;
  }
}

.button-nav-menu:before {
  width: 100%;
  height: 100%;
  left: 0;
  content: '';
  position: absolute;
  background: #fff;
  z-index: -2;
  top: 0;

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

}
.button-nav-menu:after {
  width: 100%;
  height: 100%;
  left: 0;
  content: '';
  position: absolute;
  background:  #ffba00;
  z-index: -2;
  top: 0;

  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;

}
.button-nav-menu {
  position: relative;
  border: none;
  display: block;
  border-radius: 10px;
  width: 120px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 15px;
  padding: 8px 0;
  z-index: 2;
  font-size: 16px;
  white-space: nowrap;
}
.container {
  position: relative;
}

.button-nav-menu {
  position: absolute;
  z-index: 3;
  color: #ffffff;
  border-top-left-radius: 1rem;
}

.button-nav-menu:active {
  background: #eeb713;
}
.ant-tabs-nav-container {
  transition: all 0.3s ease ;
  overflow: scroll;
  overflow-x: hidden;
  width: 157px!important;
}
.tabs-nav-container--remove .ant-tabs-nav-container {
  width: 0!important;
}
@media (min-width: 1024px) {
  .tabs-nav-container--remove .ant-tabs-nav-container {
    width: auto!important;
  }
}
.ant-tabs-nav-container--remove {
  overflow: scroll;
  overflow-x: hidden;
  transition: all 0.3s ease ;
  width: 0!important;

}


</style>

<script>

import TableEstimate from '@/components/TableEstimate.vue';
import TableSummary from './TableSummary.vue';
import CardsSummary from './SummaryCards/CardsSummary.vue';

export default {
  name: 'TablesBlock',
  components: {
    TableEstimate,
    TableSummary,
    CardsSummary,
  },
  props: {
    tables: Object,
    sums: Object,
    perRoom: Object,
    salePerRoom: Object,
    money: Number,
  },
  data: () => ({
    activeKey: 'Карточки',
    countNav :1,
  }),
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.activeKey ='Карточки';
  },
  methods: {
    handleClick(area) {
      this.activeKey = area;
      this.$forceUpdate();
    },
    handleClickOnMenu(event){


      const tabsNav= document.querySelector('.ant-tabs-nav-container')

      if(this.countNav === 1 && document.body.offsetWidth < 1024) {

        const tabs= document.querySelector('.tabs')
        tabs.classList.remove('tabs-nav-container--remove')
        tabsNav.classList.add('ant-tabs-nav-container--remove')
        this.countNav--
      }

      if(tabsNav.classList.contains('ant-tabs-nav-container--remove')) {
        return  tabsNav.classList.remove('ant-tabs-nav-container--remove')
      }

      tabsNav.classList.add('ant-tabs-nav-container--remove')

    }
  },
};
</script>
<style>
/*.ant-tabs-nav > div {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*}*/
</style>

<style scoped>


  hr {
   visibility:hidden;
   margin-bottom:-20px;
}

.cards{
  display:flex;
  justify-content:center;
  align-items: center;
}
.cards > *{
  border-radius:1rem !important;
}
.container{
  display: flex;
  flex-direction: column;
  width: 100%;
}

  .cards{
    display: flex;
    justify-content: flex-start;
  }

@media screen and (max-width: 900px) {
  .container{
    padding: 56px 10px 22px;
  }
}
@media screen  and (min-width: 1160px){
  .tabs{
    min-width: 1160px;
  }
}
@media screen and (min-width: 900px) {
  .container{
    padding-top: 80px;
    padding-bottom: 42px;
    align-items: center;
  }
}

.tabs{
  max-width: 1160px;
}
</style>
