import { render, staticRenderFns } from "./HeaderTitleNew.vue?vue&type=template&id=bde0d65c&scoped=true&"
import script from "./HeaderTitleNew.vue?vue&type=script&lang=js&"
export * from "./HeaderTitleNew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bde0d65c",
  null
  
)

export default component.exports