<script>
import logiya from '../../assets/logiya.jpg';
import electro from '../../assets/electro.jpg';
import hall from '../../assets/hall.jpg';
import kitchen from '../../assets/kitchen.jpg';
import room from '../../assets/komnata.jpg';
import spec from '../../assets/spec.jpg';
import storage from '../../assets/storage.jpg';
import toilet from '../../assets/toilet.jpeg';
import bath from '../../assets/bath.jpeg';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Card',
  props: {
    sum: Object,
    area: String,
  },
  data(){
    return {
      pics: {
        Лоджия: logiya,
        Комната: room,
        Туалет: toilet,
        Кухня: kitchen,
        Спецмонтаж: spec,
        Электромонтаж: electro,
        Коридор: hall,
        Кладовая: storage,
        Ванная: bath,
      },
    }
  },

  methods:{
    getPic(text){
      return this.pics[Object.keys(this.pics).filter(el=>String(text).includes(el))[0]]
    }
  },
  created() {
    // console.log(this.sum);
  },
};
</script>
<template>
  <div class="card" >
    <div id="bg" :style="{backgroundImage: `url(${getPic(area)})`}"/>
    <h1 class="heading">{{area}}</h1>
    <div class="statistic">
      <div class="statgroup" v-for="(val, key) in sum" :key="key" v-if="key!=='sum'&&key!=='sumsale'&&val.sum!=='0.00'">
        <h2 class="caption">{{key}}</h2>
        <div class="numbers">
          <h2 v-if="val.sum!==val.sumsale" ><strike>{{val.sum}}</strike></h2>
          <h2 >{{val.sumsale}}</h2>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
@media screen and (max-width:500px) {
  .caption {
    font-size: 1rem;
  }
  .numbers h2{
    font-size: 1rem;
  }
}
.heading{
  text-align: center;
  margin-top: 1rem;
  color: #fff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
}
#bg{
  filter: brightness(70%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-size: cover;
  border-radius: 1rem !important;
}
.card{
  position: relative;
  background-size: cover;
  height: 30rem;
  width: 20rem;

  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  border-radius: 50%;
}
 /* width */
.heading {
  margin-top: 50px;
}
.statistic{
  overflow-y:scroll ;
  scrollbar-color: #464c53 #c2d2e400;
  scrollbar-width:thin;
  filter: brightness(100%);
  background-image:  linear-gradient(#7945450f,hsla(0,0%,0%,.8));
  border-radius: 1rem;
}
.statgroup{
  display: grid;
  grid-template-columns: 2fr 2fr;
  justify-items: end;
  word-break: break-all;
  margin: 1rem 1rem 1rem;
}
.caption{
  /* text-shadow: 0.01em 0 black,
        0 0.01em black,
        -0.01em 0 black,
        0 -0.01em black; */
  color: #fff;
  grid-column-start: 1;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
line-height: 150%;
}
.numbers h2{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
line-height: 150%;
  /* text-shadow: 0.01em 0 black,
        0 0.01em black,
        -0.01em 0 black,
        0 -0.01em black; */
  color: #fff;
  filter: brightness(100%);
  grid-column-start: 2;
  margin-right: 0.5rem;
  justify-self: end;
}
h2{
  font-size: 1rem;
}
@media screen and (max-width: 500px) {
  .caption,.numbers h2 {
    font-size: 0.75rem
  }
}

</style>
