<template>
  <div class="container">
    <vs-row style="max-width: 1350px">
      <vs-col :lg="3" :sm="6" :xs="12">
        <div class="logo">
          <img src="/img/logo.png" alt="logo" />
        </div>
      </vs-col>
      <vs-col :lg="4" :sm="6" :xs="12">
        <div class="info">
          <div class="card">
            <span class="title">Полное наименование юр. лица: </span>
            <span class="description">
              Общество с ограниченной ответственностью <br />
              "Строительная Компания «Эталон Ремонт».
            </span>
          </div>
          <div class="card">
            <span class="title">Юридический адрес:</span>
            <span class="description">
              117105, г. Москва ул. Новоданиловская набережная, 6к1
            </span>
          </div>
        </div>
      </vs-col>
      <vs-col :lg="3" :sm="6" :xs="12">
        <div class="law">
          <div class="indexes">
            <div class="card">
              <span class="title">ИНН: </span>
              <span class="description">9718159690</span>
            </div>

            <div class="card">
              <span class="title">ОГРН:</span>
              <span class="description">1207700269545</span>
            </div>
          </div>
          <div class="copyright">
            <span id="title">© 2023. Все права защищены.</span>
            <span id="link"><a href="">Политика конфиденциальности.</a> </span>
          </div>
        </div>
      </vs-col>
      <vs-col :lg="2" :sm="6" :xs="12">
        <div class="contact">
          <div class="card">
            <a :href="`tel:${phone}`"
              ><span class="title">{{ phone }}</span></a
            >
          </div>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>
© 2023. Все права защищены. Политика конфиденциальности.
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  props: {
    phone: String,
  },
}
</script>

<style scoped>
a {
  color: inherit;
}
@media screen and (max-width: 900px) {
  .logo {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  .container {
    padding-top: 56px;
  }

  .info {
    margin-top: 14px;
  }
  .container {
    padding: 20px;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .law {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .info {
    padding: 0 20px;
  }

  .law {
    display: flex;
    justify-content: center;
    padding-left: 15px;
  }

  .contact {
    display: flex;
    padding: 0 20px;
  }

  .contact .card {
    padding-right: 20px;
  }

  .contact .card .title {
    font-size: 16px;
  }

  .contact .card .description {
    font-size: 10px;
  }
  .law {
    flex-direction: column;
  }

  .copyright {
    padding-left: 0;
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 900px) {
  .container {
    padding-top: 74px;
  }

  .contact .card .title {
    font-size: 24px;
  }

  .contact .card .description {
    font-size: 12px;
  }
}

@media screen and (max-width: 360px) {
}

.contact .card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
}

.contact .card .title {
  font-family: Montserrat;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: right;
  margin-bottom: 10px;
}

.contact .card .description {
  font-family: Montserrat;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  color: #ffba00;
}

#title {
  margin-bottom: 8px;
}

.indexes .card {
  padding-bottom: 10px;
}

.indexes .title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.indexes .description {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.copyright {
  display: flex;
  flex-direction: column;
}

.copyright span {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.indexes {
  padding-bottom: 20px;
}

.info .title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.info .description {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.info .card {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

img {
  max-width: 200px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  /*max-height: 300px;*/
}
</style>
